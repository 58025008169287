<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.complex')"
            :placeholder="$t('placeholder.select_complex')"
            :value="complexes.find(el => el.id === form.complex_id)"
            :options="complexes"
            required
            @select="form.complex_id = $event.id"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.billing_id')"
            v-model="form.billing_id"
            :placeholder="$t('placeholder.enter_billing_id')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppInput,
    AppMultiselect
  },
  props: {
    block: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      complexId: null,
      updatePageFlag: false,
      form: {
        billing_id: null,
        complex_id: null,
        name: null
      }
    }
  },
  beforeMount() {
    this.complexId = this.$route.query.complex_id;
    this.form.complex_id = parseInt(this.complexId);
    this.$store.dispatch('complexesStore/GET_LIST')
  },
  computed: {
    ...mapGetters({
      complexes: 'complexesStore/COMPLEXES'
    })
  },
  watch: {
    block(data) {
      if (data) {
        this.updatePageFlag = true;

        this.form.complex_id = data.complex.id;
        this.form.name = data.name;
        this.form.billing_id = data.billing_id;
      }
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>